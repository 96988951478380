import React, { useState, useEffect } from "react"
import { debounce } from "lodash"
import { useDispatch, useSelector } from "react-redux"
import { setInvitePopup, setProfilePopup, setJitsiPopup } from "../store/modules/popup"
import { setJitsiInfo } from "../store/modules/jitsiInfo"
import { Desktop, Mobile } from "../components/layouts/mediaQuery"
import LayerPopupLayout from "./LayerPopupLayout"
import Warning_Cube from "../images/Warning_Cube.svg"
import checkbox_true from "../images/checkbox_true.svg"
import checkbox_false from "../images/checkbox_false.svg"
import daumIcon from "../images/daum.svg"
import kakaoIcon from "../images/kakao.svg"
import naverIcon from "../images/naver.svg"
import urlIcon from "../images/url.svg"
import StudyMakeContainer from "../containers/StudyMakeContainer"
import MyContainer from "../containers/MyContainer"
import UserContainer from "../containers/UserContainer"
import { useMediaQuery } from "react-responsive"
import img_checkbox from "../images/img_checkbox.svg"
import img_checkbox_active from "../images/img_checkbox_active.svg"
import img_border_x from "../images/img_border_x.svg"
import img_popup_close_gray from "../images/img_popup_close_gray.svg"
import temp_profile from "../images/editicon.png"
import moment from "moment"
import api from "../utils/api"
import { navigate } from "gatsby-link"
import ModalPopup from "./ModalPopup"
const COUNT = 20
const StudyInviteListPopup = props => {
  const {
    invitationsList,
    getInvitations,
    delInvitations,

    onClose = () => {},
    open = false,
  } = props
  const dispatch = useDispatch()
  const isMobile = useMediaQuery({ maxWidth: 576 })
  const [checkList, setCheckList] = useState([])
  const [page, setPage] = useState(0)
  const [tab, setTab] = useState(0)

  const getDate = async () => {
    await getInvitations(tab)
    setPage(0)
  }

  useEffect(() => {
    if (open) {
      getDate()
    }
  }, [open])
  useEffect(() => {
    if (open) {
      getDate()
      setCheckList([])
    }
  }, [tab])
  // 전체 선택 버튼 핸들러
  const onClickAllCheckBox = () => {
    if (checkList.length === invitationsList.length && invitationsList.length !== 0) setCheckList([])
    else setCheckList(invitationsList.map(i => i.invitationSeq))
  }

  // 개별 체크 버튼 핸들러
  const onClickCheckBox = invitationSeq => {
    const temp = [...checkList]
    const findIndex = temp.findIndex(i => i === invitationSeq)

    if (findIndex === -1) {
      temp.push(invitationSeq)
      setCheckList(temp)
    } else {
      temp.splice(findIndex, 1)
      setCheckList(temp)
    }
  }

  // 메세지 삭제 버튼
  const onClickDelete = debounce(async invitationSeq => {
    let result = await delInvitations({ invitationSeqs: [invitationSeq] })
    if (result) {
      await getDate()
    }
    setCheckList([])
  }, 500)
  // 선택 삭제 버튼
  const onClickDeleteList = debounce(async () => {
    let result = await delInvitations({ invitationSeqs: checkList })
    if (result) {
      await getDate()
    }
    setCheckList([])
  }, 500)

  const closePopup = () => {
    onClose()
  }

  return (
    <LayerPopupLayout open={open} onClose={closePopup} transparency isMCloseBtn zIndex={103}>
      <div className="my-study-invite-popup-container">
        <Desktop>
          <div className="my-study-invite-popup-close-div">
            <button className="btn" onClick={closePopup}>
              <img src={img_popup_close_gray} alt="popup close img" />
            </button>
          </div>
        </Desktop>
        <p className="title">스터디 초대 목록</p>
        <div className="my-study-invite-popup-wrapper ">
          <div className="my-study-invite-popup-top-btn-div">
            <div className="my-study-invite-popup-top-btn-view">
              <button onClick={() => setTab(0)} className={tab == 0 ? "active" : ""}>
                받은 초대문자
              </button>
              <button onClick={() => setTab(1)} className={tab == 1 ? "active" : ""}>
                보낸 초대문자
              </button>
            </div>
          </div>
          <div className="list-header-view">
            <button className="btn check-button" onClick={onClickAllCheckBox}>
              <img
                src={checkList.length === invitationsList.length && invitationsList.length !== 0 ? img_checkbox_active : img_checkbox}
                alt="checkbox img"
              />
              <p>전체 선택</p>
            </button>
            <button onClick={onClickDeleteList} className="btn delete-button">
              삭제
            </button>
          </div>

          <div className="list-body-view">
            {invitationsList.map((i, idx) => (
              <div
                className={`web-margin mo-border ${checkList.findIndex(item => item === i.invitationSeq) === -1 ? "" : "active"}`}
                key={idx}
                onClick={() => isMobile && onClickCheckBox(i.invitationSeq)}
              >
                <div className={`list-item-view `}>
                  <Desktop>
                    <button className="btn check-button" onClick={() => onClickCheckBox(i.invitationSeq)}>
                      <img
                        src={checkList.findIndex(item => item === i.invitationSeq) === -1 ? img_checkbox : img_checkbox_active}
                        alt="checkbox img"
                      />
                    </button>
                  </Desktop>
                  <div className={`wrapper ${checkList.findIndex(item => item === i.invitationSeq) === -1 ? "" : "active"}`}>
                    <div style={{ display: "flex", width: "100%" }}>
                      <button
                        className="btn profile-button"
                        onClick={e => {
                          e.stopPropagation()
                          // dispatch(setProfilePopup({ open: true, data: { userSeq: i.receiverSeq } }))
                        }}
                      >
                        <img
                          className="profile-img"
                          src={i.profileImage != null && i.profileImage != "" ? i.profileImage : temp_profile}
                          alt="profile img"
                        />
                      </button>
                      <div className="info-view">
                        <div className="info-view-div-1">
                          <p className="user-name">{i.nickname != null ? i.nickname : "nickname"}</p>
                          <p className="address">{i.region3 != null ? i.region3 : ""}</p>
                        </div>
                        <div className="study-state">{i.isParticipation}</div>
                        <Desktop>
                          <div
                            onClick={async e => {
                              e.stopPropagation()

                              if (i.isParticipation.indexOf("종료") != -1) {
                                setOpenClosePopup(true)
                                return false
                              }

                              const rq_result = await api.post(`/study/participation`, {
                                token: true,
                                body: { studySeq: i.studySeq, isInvite: "N", inviter: "" },
                              })
                              if (rq_result.status === 200) {
                                dispatch(
                                  setJitsiInfo({
                                    name: i.title,
                                    seq: i.studySeq,
                                    roomInfo: rq_result.data.data.studyMemberUserInfo,
                                    aiSensitiveRules: rq_result.data.data.aiSensitiveRules,
                                  })
                                )
                                dispatch(setJitsiPopup(true))
                              }
                            }}
                            className="message-view-div"
                          >
                            <p>{i.title}</p>
                            <p>{`${
                              moment(i.dateStart).format("YYYY-MM-DD") == moment(i.dateEnd).format("YYYY-MM-DD")
                                ? moment(i.dateStart).format("MM/DD")
                                : `${moment(i.dateStart).format("MM/DD")}-${moment(i.dateEnd).format("MM/DD")}`
                            } | ${i.studySchedules[0].dayType}요일 ${i.studySchedules[0].startTime.substr(
                              0,
                              5
                            )} ~ ${i.studySchedules[0].endTime.substr(0, 5)}`}</p>
                          </div>
                        </Desktop>
                      </div>
                    </div>
                    <Mobile>
                      <div
                        onClick={() => {
                          // setOpenMessage({ open: true, data: i })
                        }}
                        className="message-view-div"
                      >
                        <p>{"스터디 타이틀 최대 30자 표시"}</p>
                        <p>{i.regDatetime}</p>
                      </div>
                    </Mobile>
                  </div>

                  <button
                    className={`btn delete-button ${checkList.findIndex(item => item === i.invitationSeq) === -1 ? "" : "active"}`}
                    onClick={() => onClickDelete(i.invitationSeq)}
                  >
                    <img src={img_border_x} alt="x img" />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="row justify-content-center">
          {/* <button className={`btn btn-primary font-white  more-button ${COUNT * (page + 1) < invitationsListTotal ? "active" : ""}`} onClick={closePopup}> */}
          <button className={`btn btn-primary font-white more-button active`} onClick={closePopup}>
            확인
          </button>
        </div>
      </div>
    </LayerPopupLayout>
  )
}

export default MyContainer(UserContainer(StudyInviteListPopup))
