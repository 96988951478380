import React, { useState, useEffect } from "react"
import LayerPopupLayout from "./LayerPopupLayout"
import img_search from "../images/img_search.svg"
import img_checkbox_active from "../images/img_checkbox_active.svg"
import img_checkbox from "../images/img_checkbox.svg"
import temp_profile from "../images/editicon.png"
import UserContainer from "../containers/UserContainer"

const MyBuddyPopup = props => {
  const { selectBuddies, setSelStudyBuddy, studyBuddies, open, onClose } = props
  const [searchText, setSearchText] = useState("")
  const [checkList, setCheckList] = useState([])
  useEffect(() => {
    if (open) setCheckList(selectBuddies)
    else setCheckList([])
  }, [open])

  // 추가 버튼 리스너
  const onClickAdd = () => {
    onClose()
    let arr = checkList
    let temp = []
    for (let i = 0; i < checkList.length; i++) {
      let checkArr = false
      for (let j = i + 1; j < checkList.length; j++) {
        if (checkList[i].userSeq == checkList[j].userSeq) {
          checkArr = true
        }
      }
      if (checkArr == false) {
        temp = [...temp, checkList[i]]
      }
    }
    setSelStudyBuddy(temp)
  }

  return (
    <LayerPopupLayout open={open} onClose={onClose} isMCloseBtn transparency zIndex={102}>
      <div className="popup-my-buddy-container">
        <p className="title">내 스터디 버디</p>

        <div className="input-view">
          <input value={searchText} onChange={e => setSearchText(e.target.value)} />
          <button className="btn search-button">
            <img src={img_search} alt="search img" />
          </button>
        </div>

        <div className="list-view">
          <button
            className="btn checkbox"
            onClick={() => {
              if (checkList.length === studyBuddies.length) setCheckList([])
              else setCheckList(studyBuddies)
            }}
          >
            <img src={checkList.length === studyBuddies.length ? img_checkbox_active : img_checkbox} alt="checkbox img" />
            <p>전체 선택</p>
          </button>
          <div className="scroll-view">
            {studyBuddies
              .filter(k => searchText == "" || k.nickname.includes(searchText))
              .map((i, idx) => (
                <div
                  className={`list-item ${checkList.findIndex(item => item.userSeq === i.userSeq) < 0 ? "" : "active"}`}
                  key={idx}
                  onClick={() => {
                    const filteredList = checkList.filter(item => item.userSeq === i.userSeq)
                    if (filteredList.length === 0) setCheckList(prev => [...prev, i])
                    else setCheckList(prev => prev.filter(item => item.userSeq !== filteredList[0].userSeq))
                  }}
                >
                  <img src={i.profileImage != null && i.profileImage != "" ? i.profileImage : temp_profile} alt="profile_img" />

                  <div>
                    <p>{i.nickname != null && i.nickname != "" ? i.nickname : "nickname"}</p>
                    <p>{i.region3}</p>
                  </div>
                </div>
              ))}
          </div>
        </div>

        <div className="button-view">
          <button className="btn btn-primary" onClick={onClose}>
            닫기
          </button>
          <button className="btn btn-primary font-white" onClick={onClickAdd}>
            추가
          </button>
        </div>
      </div>
    </LayerPopupLayout>
  )
}

export default UserContainer(MyBuddyPopup)
