/*
  마이렛미업 내 스터디 스케줄 페이지
*/
import React, { useCallback, useEffect, useState } from "react"
import moment from "moment"
import Swiper from "react-id-swiper"
import "swiper/scss"
import SwipeableViews from "react-swipeable-views"
import { useDispatch } from "react-redux"
import { setJitsiClose, setEditToDoPopup, setMakeToDoPopup, setJitsiPopup, setProfilePopup } from "../../store/modules/popup"
import { setJitsiInfo } from "../../store/modules/jitsiInfo"
import SEO from "../../components/seo"
import { Desktop, Mobile } from "../../components/layouts/mediaQuery"
import Layout from "../../components/Layout"
import LeftSideBar from "../../components/my/LeftSideBar"
import RightSideBar from "../../components/my/RightSideBar"
import StudyItem from "../../components/common/StudyItem"
import MakeToDoPopup from "../../popups/MakeToDoPopup"
import EditToDoPopup from "../../popups/EditToDoPopup"
import img_schedule_left from "../../images/img_schedule_left.svg"
import img_schedule_right from "../../images/img_schedule_right.svg"
import img_schedule_dropdown from "../../images/img_schedule_dropdown.svg"
import img_schedule_item from "../../images/img_schedule_item.svg"

import temp_profile from "../../images/editicon.png"
import MyContainer from "../../containers/MyContainer"
import SignContainer from "../../containers/SignContainer"
import UserContainer from "../../containers/UserContainer"
import PrivateRoute from "../../components/privateRoute"
import { useSelector } from "react-redux"
import PieChart from "../../components/common/PieChart"
import { navigate } from "gatsby-link"
import api from "../../utils/api"
import StudyInviteListPopup from "../../popups/StudyInviteListPopup"
import TodayTodoPopup from "../../popups/TodayTodoPopup"
import { localStorgeGet, rnMessageListener } from "../../utils/util"
import ModalPopup from "../../popups/ModalPopup"
import SignApis from "../../apis/SignApis"

const Schedule = props => {
  const {
    myStudiesListPc,
    todayTodoList,
    getTodayTodos,
    isLogin,
    getTodoTasks,
    todoTasksList,
    getMyParticipatingStudies,
    myStudiesList,
    getUserStudyBuddies,
  } = props
  const makeToDoPopup = useSelector(state => state.popup.makeToDoPopup)
  const editToDoPopup = useSelector(state => state.popup.editToDoPopup)
  const jitsiPopup = useSelector(state => state.popup.jitsiPopup)
  const dispatch = useDispatch()
  // 참여 중인 스터디
  const [swiperIndex, setSwiperIndex] = useState(0)
  const [swiperIndexPc, setSwiperIndexPc] = useState(0)
  // 오늘 공부 일정
  const [displayDate, setDisplayDate] = useState(moment())
  const [activeIndex, setActiveIndex] = useState(parseInt(moment().format("DD")) - 1)
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const [openClosePopup, setOpenClosePopup] = useState(false)

  const cardColor = {
    "취업/자격증": 5,
    중학생: 1,
    고등학생: 2,
    대학생: 3,
    대입수험생: 4,
    공무원: 6,
    기타: 8,
    직장인: 7,
  }
  const [openStudyInvitePopup, setOpenStudyInbitePopup] = useState(false)
  const [openTodayTodoPopup, setOpenTodayTodoPopup] = useState(false)
  const getTodo = async () => {
    await getTodayTodos()
    await getTodoTasks(
      moment(displayDate)
        .add(activeIndex + 1 - parseInt(displayDate.format("DD")), "day")
        .format("YYYY-MM-DD")
    )
  }
  const getStudies = async () => {
    //참여스터디
    let type = await SignApis.postAuthentication()
    await getMyParticipatingStudies({ managerUserSeq: localStorgeGet("loginUserSeq"), managerUserType: type?.data?.loginUserType || "" })
    //친구목록
    await getUserStudyBuddies({ page: 0, count: 9999999 })
  }

  useEffect(() => {
    getTodo()
  }, [activeIndex, makeToDoPopup, editToDoPopup, displayDate, openStudyInvitePopup, openTodayTodoPopup])
  useEffect(() => {
    getStudies()
    return () => {
      dispatch(setEditToDoPopup({ open: false, data: {} }))
      dispatch(setMakeToDoPopup({ open: false, data: {} }))
      dispatch(setJitsiPopup(false))
      dispatch(setProfilePopup({ open: false, data: {} }))
    }
  }, [])
  const makeCalendar = () => {
    const startDay = displayDate.clone().startOf("month").date()
    const endDay = displayDate.clone().endOf("month").date()
    let calendar = []

    for (let i = startDay; i <= endDay; i++) {
      calendar.push(
        <button className={`btn swiper-item ${activeIndex === i - 1 ? "active" : ""}`} key={i + ""} onClick={() => setActiveIndex(i - 1)}>
          <p className="string">
            {displayDate
              .clone()
              .startOf("month")
              .add(i - 1, "day")
              .format("ddd")
              .toLocaleUpperCase()}
          </p>
          <p className="number">{i}</p>
        </button>
      )
    }

    return calendar
  }
  const messageDataFn = useCallback(
    e => {
      let data = JSON.parse(e.data)
      if (data.type == "BACK") {
        if (jitsiPopup) {
          dispatch(setJitsiClose(true))
          setTimeout(() => {
            dispatch(setJitsiClose(false))
            dispatch(setJitsiPopup(false))
            dispatch(setJitsiInfo({ name: "", seq: "", roomInfo: {} }))
          }, 3000)
        } else {
          navigate(-1)
        }
      }
    },
    [jitsiPopup]
  )
  useEffect(() => {
    if (window.ReactNativeWebView) {
      document.addEventListener("message", messageDataFn)
    }
    return () => {
      document.removeEventListener("message", messageDataFn)
    }
  }, [])
  return (
    <PrivateRoute>
      <Layout title="내 공부 스케줄">
        <SEO title="마이렛미업" />
        <MakeToDoPopup />
        <EditToDoPopup />
        <StudyInviteListPopup open={openStudyInvitePopup} onClose={() => setOpenStudyInbitePopup(false)} />
        <TodayTodoPopup open={openTodayTodoPopup} onClose={() => setOpenTodayTodoPopup(false)} />
        <ModalPopup open={openClosePopup} onClose={() => setOpenClosePopup(false)} contents={"종료된 스터디 또는 스터디 참여시간이 아닙니다."} />
        <div className="d-flex justify-content-center ">
          <LeftSideBar />
          <div className="container my-container">
            {/* 참여 중인 스터디 */}
            <div className="my-schedule-div-1">
              <p className="my-schedule-title">내 공부 스케줄</p>

              <div className="my-schedule-title-view">
                <p>참여 중인 스터디</p>
                <Desktop>
                  <button onClick={() => setOpenStudyInbitePopup(true)} className="btn btn-primary font-white my-schedule-to-to-add-button">
                    스터디 초대 목록
                  </button>
                </Desktop>
                <Mobile>
                  <button onClick={() => setOpenStudyInbitePopup(true)} className="btn btn-primary font-white my-schedule-to-to-add-button">
                    스터디 초대
                    <br /> 목록
                  </button>
                </Mobile>
              </div>

              <div className="my-schedule-card-view">
                <button
                  className="btn card-left-button"
                  onClick={() => {
                    if (swiperIndexPc === 0) return
                    setSwiperIndexPc(prev => prev - 1)
                  }}
                >
                  <img src={img_schedule_left} alt="left arrow img" />
                </button>
                <button
                  className="btn card-right-button"
                  onClick={() => {
                    if (swiperIndexPc == myStudiesListPc.length - 1) return
                    setSwiperIndexPc(prev => prev + 1)
                  }}
                >
                  <img src={img_schedule_right} alt="right arrow img" />
                </button>

                <div className="card-swiper-view">
                  <Mobile>
                    {myStudiesList.length != 0 && (
                      <SwipeableViews index={swiperIndex} onChangeIndex={idx => setSwiperIndex(idx)}>
                        {myStudiesList.map((i, idx) => (
                          <StudyItem
                            key={idx}
                            data={i}
                            onClickStudy={async e => {
                              e.stopPropagation()
                              if (isLogin == false) {
                                navigate("/exploring")
                                dispatch(setLoginPopup(true))
                                return false
                              } else {
                                if (
                                  i.isParticipation.indexOf("종료") != -1 ||
                                  moment().format("YYYY-MM-DD") > i.dateEnd ||
                                  moment().format("YYYY-MM-DD") < i.dateStart
                                ) {
                                  setOpenClosePopup(true)
                                  return false
                                }
                                const rq_result = await api.post(`/study/participation`, {
                                  token: true,
                                  body: { studySeq: i.studySeq, isInvite: "N", inviter: "" },
                                })
                                if (rq_result.status === 200) {
                                  dispatch(
                                    setJitsiInfo({
                                      name: i.title,
                                      seq: i.studySeq,
                                      roomInfo: rq_result.data.data.studyMemberUserInfo,
                                      aiSensitiveRules: rq_result.data.data.aiSensitiveRules,
                                    })
                                  )
                                  dispatch(setJitsiPopup(true))
                                }
                              }
                            }}
                          />
                        ))}
                      </SwipeableViews>
                    )}
                  </Mobile>
                </div>
                <div className="card-swiper-view=pc">
                  <Desktop>
                    <SwipeableViews index={swiperIndexPc} onChangeIndex={idx => setSwiperIndexPc(idx)}>
                      {myStudiesListPc.map((i, idx) => (
                        <div key={idx} className="d-flex  ">
                          {i.map((item, index) => (
                            <div style={{ width: " 340px", margin: "0px 5px" }} key={index}>
                              <StudyItem
                                data={item}
                                onClickStudy={async e => {
                                  e.stopPropagation()
                                  if (isLogin == false) {
                                    navigate("/exploring")
                                    dispatch(setLoginPopup(true))
                                    return false
                                  } else {
                                    if (
                                      item.isParticipation.indexOf("종료") != -1 ||
                                      moment().format("YYYY-MM-DD") > item.dateEnd ||
                                      moment().format("YYYY-MM-DD") < item.dateStart
                                    ) {
                                      setOpenClosePopup(true)
                                      return false
                                    }
                                    const rq_result = await api.post(`/study/participation`, {
                                      token: true,
                                      body: { studySeq: item.studySeq, isInvite: "N", inviter: "" },
                                    })
                                    if (rq_result.status === 200) {
                                      dispatch(
                                        setJitsiInfo({
                                          name: item.title,
                                          seq: item.studySeq,
                                          roomInfo: rq_result.data.data.studyMemberUserInfo,
                                          aiSensitiveRules: rq_result.data.data.aiSensitiveRules,
                                        })
                                      )
                                      dispatch(setJitsiPopup(true))
                                    }
                                  }
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      ))}
                    </SwipeableViews>
                  </Desktop>
                </div>
              </div>
              <Mobile>
                <div className="my-schedule-swiper-indicator-view">
                  {myStudiesList.length <= 10 &&
                    myStudiesList.map((i, idx) => (
                      <button
                        className={`btn my-schedule-swiper-indicator-item ${swiperIndex === idx ? "active" : ""}`}
                        key={idx}
                        onClick={() => setSwiperIndex(idx)}
                      ></button>
                    ))}
                  {myStudiesList.length > 10 && (
                    <p>
                      {swiperIndex + 1} / {myStudiesList.length}
                    </p>
                  )}
                </div>
              </Mobile>
              <Desktop>
                <div className="my-schedule-swiper-indicator-view">
                  {myStudiesListPc.length <= 10 &&
                    myStudiesListPc.map((i, idx) => (
                      <button
                        className={`btn my-schedule-swiper-indicator-item ${swiperIndexPc === idx ? "active" : ""}`}
                        key={idx}
                        onClick={() => setSwiperIndexPc(idx)}
                      ></button>
                    ))}
                  {myStudiesListPc.length > 10 && (
                    <p>
                      {swiperIndexPc + 1} / {myStudiesListPc.length}
                    </p>
                  )}
                </div>
              </Desktop>
            </div>

            {/* 오늘 공부 일정 */}
            <div className="my-schedule-div-2">
              <div className="my-schedule-title-view">
                <p>
                  오늘 공부 일정 <span>최근 업데이트 {moment().format("HH:mm")}</span>
                </p>
                <Desktop>
                  <div className="row  align-items-center">
                    {todayTodoList.length > 0 && (
                      <button onClick={() => setOpenTodayTodoPopup(true)} className="btn btn-primary font-white my-schedule-to-to-add-button">
                        TO-DO 알림
                      </button>
                    )}
                    <button
                      className="btn btn-primary font-white my-schedule-to-to-add-button"
                      onClick={() => {
                        if (
                          moment().format("YYYYMMDD") >
                          moment(displayDate)
                            .add(activeIndex + 1 - parseInt(displayDate.format("DD")), "day")
                            .format("YYYYMMDD")
                        )
                          return false
                        dispatch(
                          setMakeToDoPopup({
                            open: true,
                            data: {
                              todoDate: moment(displayDate)
                                .add(activeIndex + 1 - parseInt(displayDate.format("DD")), "day")
                                .format("YYYYMMDD"),
                            },
                          })
                        )
                      }}
                    >
                      TO-DO 추가
                    </button>
                  </div>
                </Desktop>
              </div>

              {/* calendar */}
              <div className="my-schedule-calendar-view">
                <button
                  className="btn swiper-left-button"
                  onClick={() => {
                    if (activeIndex === 0) return
                    setActiveIndex(prev => prev - 1)
                  }}
                >
                  <img src={img_schedule_left} alt="left arrow img" />
                </button>
                <button
                  className="btn swiper-right-button"
                  onClick={() => {
                    if (activeIndex === displayDate.clone().endOf("month").date() - 1) return
                    setActiveIndex(prev => prev + 1)
                  }}
                >
                  <img src={img_schedule_right} alt="right arrow img" />
                </button>
                <div className="my-schedule-calendar-swiper-view">
                  <button
                    className={`btn swiper-select-button ${dropdownOpen ? "active" : ""}`}
                    onClick={() => setDropdownOpen(!dropdownOpen)}
                    onBlur={() => setDropdownOpen(false)}
                  >
                    <p>{displayDate.format("MMM").toLocaleUpperCase()}</p>
                    <img src={img_schedule_dropdown} alt="dropdown img" />
                  </button>

                  <div className={`swiper-select-options ${dropdownOpen ? "active" : ""}`}>
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((i, idx) => (
                      <button
                        className={`btn swiper-select-option ${displayDate.get("month") === i - 1 ? "active" : ""}`}
                        key={idx}
                        onMouseDown={() =>
                          setDisplayDate(
                            moment()
                              .clone()
                              .set("month", i - 1)
                          )
                        }
                      >
                        {moment()
                          .clone()
                          .set("month", i - 1)
                          .format("MMM")
                          .toLocaleUpperCase()}
                      </button>
                    ))}
                  </div>

                  <div className="my-schedule-calendar-swiper-wrapper">
                    <Swiper shouldSwiperUpdate slidesPerView={"auto"} spaceBetween={6} activeSlideKey={activeIndex + ""}>
                      {makeCalendar()}
                    </Swiper>
                  </div>
                </div>
              </div>

              <Mobile>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <button
                    className="btn btn-primary font-white my-schedule-to-to-add-button"
                    onClick={() =>
                      dispatch(
                        setMakeToDoPopup({
                          open: true,
                          data: {
                            todoDate: moment(displayDate)
                              .add(activeIndex + 1 - parseInt(displayDate.format("DD")), "day")
                              .format("YYYYMMDD"),
                          },
                        })
                      )
                    }
                  >
                    TO-DO 추가
                  </button>
                </div>
              </Mobile>

              {/* yes schedule */}
              {todoTasksList.length > 0 ? (
                <div className="my-schedule-yes-item-view">
                  <Swiper slidesPerView="auto" spaceBetween={126} activeSlideKey={activeIndex + ""} centeredSlides noSwiping>
                    {Array(displayDate.clone().endOf("month").date())
                      .fill(0)
                      .map((i, idx) => (
                        <div className="my-schedule-yes-item-list-wrapper" key={idx}>
                          {todoTasksList.length != 0 &&
                            todoTasksList.map((data, index) => (
                              <div
                                onClick={() => {
                                  dispatch(
                                    setEditToDoPopup({
                                      open: true,
                                      data: {
                                        ...data,
                                        todoDate: moment(displayDate)
                                          .add(activeIndex + 1 - parseInt(displayDate.format("DD")), "day")
                                          .format("YYYYMMDD"),
                                      },
                                    })
                                  )
                                }}
                                key={index}
                                className={`my-schedule-yes-item-list color-${
                                  data.studentGroupName == null ? "8" : cardColor[data.studentGroupName]
                                }`}
                              >
                                <div className="flex-view">
                                  <div className="text-view">
                                    <p>{`${data.startTime.split(":")[0]}:${data.startTime.split(":")[1]} ~ ${data.endTime.split(":")[0]}:${
                                      data.endTime.split(":")[1]
                                    } `}</p>
                                    <p>{data.content}</p>
                                  </div>
                                  <div className="profile-view">
                                    {data.shareMembers.map((i, idx) => (
                                      <img
                                        key={`participate_buddy_img${idx}`}
                                        src={i.profileImage != "" && i.profileImage != null ? i.profileImage : temp_profile}
                                        alt="profile"
                                      />
                                    ))}
                                  </div>
                                </div>
                                <div className="time-view">
                                  <PieChart dataArr={[data.percent, 100 - data.percent]} color={data.studentGroupName}></PieChart>
                                </div>
                              </div>
                            ))}
                        </div>
                      ))}
                  </Swiper>
                </div>
              ) : (
                <div className="my-schedule-no-item-view">
                  <img src={img_schedule_item} alt="no schedule img" />
                  <p>{`TO-DO 스케줄을 등록하고\n내 공부를 관리해보세요!`}</p>
                </div>
              )}

              {/* no schedule */}
            </div>
          </div>
          <RightSideBar />
        </div>
      </Layout>
    </PrivateRoute>
  )
}

export default UserContainer(SignContainer(MyContainer(Schedule)))
