import React, { useState, useEffect } from "react"
import { debounce, throttle } from "lodash"
import { useDispatch, useSelector } from "react-redux"
import { setMakeToDoPopup } from "../store/modules/popup"
import { Desktop, Mobile } from "../components/layouts/mediaQuery"
import LayerPopupLayout from "./LayerPopupLayout"
import MyBuddyPopup from "./MyBuddyPopup"
import img_checkbox from "../images/img_checkbox.svg"
import img_checkbox_active from "../images/img_checkbox_active.svg"
import img_schedule_performance_0 from "../images/img_schedule_performance_0.svg"
import img_schedule_performance_25 from "../images/img_schedule_performance_25.svg"
import img_schedule_performance_50 from "../images/img_schedule_performance_50.svg"
import img_schedule_performance_75 from "../images/img_schedule_performance_75.svg"
import img_schedule_performance_100 from "../images/img_schedule_performance_100.svg"
import img_schedule_share_right from "../images/img_schedule_share_right.svg"
import img_schedule_close from "../images/img_schedule_close.svg"
import temp_profile from "../images/editicon.png"
import PrivateRoute from "../components/privateRoute"
import MyContainer from "../containers/MyContainer"
import UserContainer from "../containers/UserContainer"
import Validator from "validator"
import { popupFn } from "../utils/util"

const MakeToDoPopup = props => {
  const { postTodoTaskAdd, selectBuddies, setSelStudyBuddy } = props
  const dispatch = useDispatch()
  const makeToDoPopup = useSelector(state => state.popup.makeToDoPopup)
  const [myBuddyPopup, setMyBuddyPopup] = useState(false)
  //
  const [keyword, setKeyword] = useState("")
  //
  const [startToggle, setStartToggle] = useState(0) // 0: 오전, 1: 오후
  const [endToggle, setEndToggle] = useState(0)
  const [startTime, setStartTime] = useState({ m: "", s: "" })
  const [endTime, setEndTime] = useState({ m: "", s: "" })
  const [check, setCheck] = useState(false) // 하루 종일
  const [radio, setRadio] = useState(-1) // 0: 1회, 1: 반복
  const [delCheck, setDelCheck] = useState(false)
  //
  const [studyContent, setStudyContent] = useState("")
  //
  const [performance, setPerformance] = useState(-1)
  //
  const [open, setOpen] = useState(false)

  useEffect(() => {
    return () => initState()
  }, [])

  useEffect(() => {
    if (parseInt(startTime.m) >= 12) {
      setStartToggle(1)
    } else {
      setStartToggle(0)
    }
    if (parseInt(endTime.m) >= 12) {
      setEndToggle(1)
    } else {
      setEndToggle(0)
    }
  }, [startTime, endTime])

  const addTodoFn = debounce(
    async e => {
      // e.preventDefault()
      //공백검사 arr
      let checkArr = [startTime.m, startTime.s, endTime.m, endTime.s, keyword]
      let isEmpty = false
      for (let i = 0; i < checkArr.length; i++) {
        if (check == false && Validator.isEmpty(checkArr[i], { ignore_whitespace: true })) {
          isEmpty = true
        }
      }
      if (isEmpty == true) {
        return false
      }
      //태그 #으로 나누기
      let keywordTemp = keyword.split("#")
      let keywordTempArr = []

      //태크 앞뒤 공백제거
      for (let j = 0; j < keywordTemp.length; j++) {
        if (!Validator.isEmpty(keywordTemp[j], { ignore_whitespace: true })) {
          keywordTempArr = [...keywordTempArr, keywordTemp[j].trim()]
        }
      }
      //키워드(컨텐츠 #제거)
      let tempArr = []
      if (selectBuddies.length > 0) {
        for (let i = 0; i < selectBuddies.length; i++) {
          tempArr = [...tempArr, selectBuddies[i].userSeq]
        }
      }
      let temp = {
        todoDate: makeToDoPopup.data.todoDate,
        startTime: check == true ? "00:00" : startTime.m + ":" + startTime.s,
        endTime: check == true ? "24:00" : endTime.m + ":" + endTime.s,
        content: studyContent,
        percent: performance == -1 ? 0 : performance * 25,
        isOpen: open == true ? "Y" : "N",
        isLoop: "N",
        tags: keywordTempArr,
        shareMembers: tempArr,
      }
      let result = await postTodoTaskAdd(temp)
      if (result) {
        closePopup()
      }
    },
    3000,
    { leading: true, trailing: false }
  )

  const closePopup = e => {
    setSelStudyBuddy([])
    initState()
    dispatch(setMakeToDoPopup({ open: false, data: {} }))
  }

  const initState = () => {
    setKeyword("")
    setStartToggle(0)
    setEndToggle(0)
    setStartTime({ m: "", s: "" })
    setEndTime({ m: "", s: "" })
    setCheck(false)
    setRadio(-1)
    setStudyContent("")
    setPerformance(-1)
    setOpen(false)
  }

  return (
    <PrivateRoute>
      <LayerPopupLayout open={makeToDoPopup.open} onClose={() => closePopup()} isCloseBtn zIndex={101}>
        <MyBuddyPopup open={myBuddyPopup} onClose={() => setMyBuddyPopup(false)} />

        <div className="popup-make-to-do-container">
          <Desktop>
            <p className="popup-make-to-do-title">TO-DO 추가</p>
          </Desktop>
          <Mobile>
            <div className="popup-make-to-do-header">
              <a className="cancel-button" onClick={() => closePopup()}>
                취소
              </a>
              <p className="popup-make-to-do-title">TO-DO 추가</p>
              <a className="save-button" onClick={() => addTodoFn()}>
                저장
              </a>
            </div>
          </Mobile>

          <div className="popup-make-to-do-body">
            {/* 키워드 */}
            <div className="popup-make-to-do-keyword-view">
              <p>
                TO-DO 스케줄
                <span>#키워드를 입력해주세요.</span>
              </p>
              <div>
                <div className="bar" />
                <Desktop>
                  <input type="text" placeholder={"#수능준비_6개월"} value={keyword} onChange={e => setKeyword(e.target.value)} />
                </Desktop>
                <Mobile>
                  <input type="text" placeholder={"TO-DO 스케줄 #키워드를 입력해주세요"} value={keyword} onChange={e => setKeyword(e.target.value)} />
                </Mobile>
              </div>
            </div>
            {/* 시간 */}
            <div className="popup-make-to-do-time-view">
              <p>공부 계획 시간을 입력해주세요.</p>
              <div className="mobile-only-view">
                <p>하루 종일</p>
                <div className="form-check form-switch form-switch-md">
                  <input checked={check} onChange={() => setCheck(!check)} className="form-check-input" type="checkbox" />
                </div>
              </div>
              <div className="input-view">
                <div>
                  <p>시작</p>
                  <div>
                    <ToggleButton index={startToggle} setIndex={setStartToggle} />
                    <InputView value={startTime} setValue={setStartTime} />
                  </div>
                </div>
                <div>
                  <p>종료</p>
                  <div>
                    <ToggleButton index={endToggle} setIndex={setEndToggle} />
                    <InputView value={endTime} setValue={setEndTime} />
                  </div>
                </div>
              </div>
              <div className="check-view">
                <button className="btn" onClick={() => setCheck(!check)}>
                  <img src={check ? img_checkbox_active : img_checkbox} alt="checkbox img" />
                  <p>하루 종일</p>
                </button>
                <div>
                  {/* <button className="btn" onClick={() => setRadio(radio === 0 ? -1 : 0)}>
                    <img src={radio === 0 ? img_checkbox_active : img_checkbox} alt="checkbox img" />
                    <p>1회</p>
                  </button>
                  <button className="btn" onClick={() => setRadio(radio === 1 ? -1 : 1)}>
                    <img src={radio === 1 ? img_checkbox_active : img_checkbox} alt="checkbox img" />
                    <p>반복</p>
                  </button> */}
                </div>
              </div>
            </div>
            {/* 공부 내용 */}
            <div className="popup-make-to-do-study-view">
              <p>
                <Desktop>공부할 내용을 입력해주세요.</Desktop>
                <Mobile>계획한 시간에 공부할 내용을 입력해주세요</Mobile>
              </p>
              <div>
                <div className="bar" />
                <Desktop>
                  <input
                    type="text"
                    placeholder="영어 단어/문장 오답노트"
                    value={studyContent}
                    onChange={e => setStudyContent(e.target.value)}
                    maxLength={30}
                  />
                </Desktop>
                <Mobile>
                  <input type="text" placeholder="공부 내용" value={studyContent} onChange={e => setStudyContent(e.target.value)} maxLength={30} />
                </Mobile>
                <p>( {studyContent.length}/30자 )</p>
              </div>
            </div>
            {/* 수행도 */}
            <div className="popup-make-to-do-performance-view">
              <p>계획한 시간 동안의 수행도를 선택해주세요.</p>
              <div>
                <button className={`btn ${performance === 0 ? "active" : ""}`} onClick={() => setPerformance(0)}>
                  <img src={img_schedule_performance_0} alt="0% img" />
                  <p>0%</p>
                </button>
                <button className={`btn ${performance === 1 ? "active" : ""}`} onClick={() => setPerformance(1)}>
                  <img src={img_schedule_performance_25} alt="25% img" />
                  <p>25%</p>
                </button>
                <button className={`btn ${performance === 2 ? "active" : ""}`} onClick={() => setPerformance(2)}>
                  <img src={img_schedule_performance_50} alt="50% img" />
                  <p>50%</p>
                </button>
                <button className={`btn ${performance === 3 ? "active" : ""}`} onClick={() => setPerformance(3)}>
                  <img src={img_schedule_performance_75} alt="75% img" />
                  <p>75%</p>
                </button>
                <button className={`btn ${performance === 4 ? "active" : ""}`} onClick={() => setPerformance(4)}>
                  <img src={img_schedule_performance_100} alt="100% img" />
                  <p>100%</p>
                </button>
              </div>
            </div>
            {/* 공개 설정 */}
            <div className="popup-make-to-do-setting-view">
              <p>공개 설정</p>
              <div className="form-check form-switch form-switch-md">
                <input checked={open} onChange={() => setOpen(!open)} className="form-check-input" type="checkbox" />
              </div>
            </div>
            {/* 공유 */}
            <div className="popup-make-to-do-share-view">
              <div className="title-view">
                <p>친구와 공유</p>
                <button className="btn" onClick={() => setMyBuddyPopup(true)}>
                  <p>
                    <Desktop>스터디 버디 추가</Desktop>
                    <Mobile>친구 추가</Mobile>
                  </p>
                  <img src={img_schedule_share_right} alt="right arrow img" />
                </button>
              </div>
              <div className="freind-view">
                {selectBuddies.map((i, idx) => (
                  <div className="freind-item" key={idx}>
                    <button
                      onClick={() => {
                        let temp = selectBuddies
                        temp.splice(idx, 1)
                        setSelStudyBuddy(temp)
                        setDelCheck(!delCheck)
                      }}
                      className="btn"
                    >
                      <img src={img_schedule_close} alt={"x img"} />
                    </button>
                    <img src={i.nickname != null && i.profileImage != "" ? i.profileImage : temp_profile} alt="profile img" />
                    <p>{i.nickname != null && i.nickname != "" ? i.nickname : "nickname"}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* 하단 버튼 */}
          <div className="popup-make-to-do-button-view">
            <button className="btn btn-primary reverse" onClick={() => closePopup()}>
              취소
            </button>
            <button onClick={() => addTodoFn()} className="btn btn-primary font-white">
              확인
            </button>
          </div>
        </div>
      </LayerPopupLayout>
    </PrivateRoute>
  )
}

export default UserContainer(MyContainer(MakeToDoPopup))

const ToggleButton = ({ index, setIndex }) => {
  return (
    <div className="popup-make-to-do-time-toggle">
      <button style={{ cursor: "initial" }} className={`btn ${index === 0 ? "active" : ""}`}>
        오전
      </button>
      <button style={{ cursor: "initial" }} className={`btn ${index === 1 ? "active" : ""}`}>
        오후
      </button>
    </div>
  )
}
const reg = /^[ㄱ-ㅎ|ㅏ-ㅣ|가-힣a-zA-Z]/

const InputView = ({ value, setValue }) => {
  return (
    <div className="popup-make-to-do-time-input">
      <input
        value={value.m}
        onChange={e => {
          if (e.target.value > 24 || reg.test(e.target.value)) return
          setValue({ ...value, m: e.target.value })
        }}
        maxLength={2}
      />
      <p>:</p>
      <input
        value={value.s}
        onChange={e => {
          if (e.target.value > 59 || reg.test(e.target.value)) return
          setValue({ ...value, s: e.target.value })
        }}
        maxLength={2}
      />
    </div>
  )
}
