import React, { useState, useEffect } from "react"
import LayerPopupLayout from "./LayerPopupLayout"
import img_search from "../images/img_search.svg"
import img_checkbox_active from "../images/img_checkbox_active.svg"
import img_checkbox from "../images/img_checkbox.svg"
import temp_profile from "../images/editicon.png"
import UserContainer from "../containers/UserContainer"
import MyContainer from "../containers/MyContainer"
import { useDispatch } from "react-redux"
import { dataCheckFn } from "../utils/util"
import moment from "moment"
import { Desktop } from "../components/layouts/mediaQuery"
import ModalPopup from "./ModalPopup"

const reg = /^[ㄱ-ㅎ|ㅏ-ㅣ|가-힣a-zA-Z]/

const TodayTodoPopup = props => {
  const { postTodosByAlarm, setSelStudyBuddy, open, onClose, todayTodoList, getTodayTodos } = props
  const [checkList, setCheckList] = useState([])
  const [timeList, setTimeList] = useState([])
  const [inputValue, setInputValue] = useState("")
  const [openErrModal, setOpenErrModal] = useState(false)
  const dispatch = useDispatch()
  useEffect(() => {}, [open])

  // 추가 버튼 리스너
  const onClickAdd = async () => {
    let arr = checkList
    if (arr.length == 0) {
      return false
    }
    for (let i = 0; i < arr.length; i++) {
      let seq1 = todayTodoList[i].todoSeq || todayTodoList[i].todoTaskSeq
      for (let j = 0; j < todayTodoList.length; j++) {
        let seq2 = todayTodoList[j].todoSeq || todayTodoList[j].todoTaskSeq
        if (seq1 == seq2) {
          arr[i].startTime = timeList[j][0] + ":" + timeList[j][1]
          arr[i].endTime = timeList[j][2] + ":" + timeList[j][3]
          arr[i].seq = seq2
        }
      }
    }
    let check = false
    for (let i of arr) {
      let start = moment("2000-01-01 " + i.startTime)
      let end = moment("2000-01-01 " + i.endTime)
      if (start.isAfter(end)) {
        check = true
      }
    }
    if (check) {
      setOpenErrModal(true)
      return false
    }
    let result = await postTodosByAlarm({
      todosByAlarm: [
        ...arr.map((i, idx) => {
          return { isFormal: i.isFormal, seq: i.seq, startTime: i.startTime, endTime: i.endTime }
        }),
      ],
    })
    if (result) {
      onClose()
    }
  }
  const timeSetFn = () => {
    let temp = []
    for (let i in todayTodoList) {
      temp = [...temp, ["", "", "", ""]]
    }
    setTimeList(temp)
  }

  useEffect(() => {
    if (open) {
      timeSetFn()
    }
  }, [open])

  return (
    <LayerPopupLayout open={open} onClose={onClose} isMCloseBtn transparency zIndex={103}>
      <ModalPopup open={openErrModal} onClose={() => setOpenErrModal(false)} contents={"시간입력이 잘못되었습니다."} />
      <div className="my-todo-notice-popup-container">
        <Desktop>
          <p className="title">오늘의 TO-DO 스케줄</p>
        </Desktop>
        <div className="my-todo-notice-popup-inner">
          <p className="inner-sub-title">오늘의 TO-DO 스케줄</p>
          <p className="inner-title">
            공부 계획 시간 입력하고
            <br />
            TO-DO 스케줄 관리하세요.
          </p>
          {todayTodoList.map((i, idx) => (
            <div className="todo-notice-item" key={idx}>
              <div
                onClick={() => {
                  const filteredList = checkList.filter(item => (item.todoSeq ? item.todoSeq == i.todoSeq : item.todoTaskSeq == i.todoTaskSeq))
                  if (filteredList.length === 0) setCheckList(prev => [...prev, i])
                  else
                    setCheckList(prev =>
                      prev.filter(item =>
                        item.todoSeq ? item.todoSeq !== filteredList[0].todoSeq : item.todoTaskSeq !== filteredList[0].todoTaskSeq
                      )
                    )
                }}
                className="todo-notice-item-top-div"
              >
                <div className="todo-notice-item-top-div-1">
                  <img
                    src={
                      checkList.findIndex(item => (item.todoSeq ? item.todoSeq == i.todoSeq : item.todoTaskSeq == i.todoTaskSeq)) === -1
                        ? img_checkbox
                        : img_checkbox_active
                    }
                    alt="checkbox img"
                  />
                  <p>{i.owner}</p>
                </div>
                <p>{i.regDatetime}</p>
              </div>
              <div className="todo-notice-item-inner-box">
                <div className="todo-notice-item-inner-top">
                  <p className="todo-notice-item-inner-title">{i.title || i.keyword}</p>
                  <div className="row">
                    {i.subject && <p className="todo-info1">{i.subject}</p>}
                    {i.book && <p className="todo-info1">{i.book}</p>}
                    {i.rangeType && (
                      <p className="todo-info2">
                        {i.rangeType == "PAGE" ? "페이지" : "챕터"} {i.rangeStart} - {i.rangeEnd}
                      </p>
                    )}
                    {i.estimatedTimeRequired && (
                      <p className="todo-info2">{i.estimatedTimeRequired != null ? `(예상소요시간 ${i.estimatedTimeRequired})` : ""}</p>
                    )}
                    {i.mediaContentUrl && <p className="todo-info3">{i.mediaContentUrl != null ? i.mediaContentUrl : ""}</p>}
                    <p className="todo-info4">{i.comment || i.content}</p>
                  </div>
                </div>
                <div className="todo-notice-item-inner-bottom">
                  <p>공부 계획시간을 입력해주세요.</p>
                  {/* {todoTimeList.length > idx && <InputView value={todoTimeList} setvalue={setTodoTimeList} idx={idx} />} */}
                  {timeList.length > idx && (
                    <div className="todo-notice-item-inner-bottom-time-div">
                      <div className="time-box">
                        <p className="text-p">시작시간</p>
                        <input
                          // value={inputValue}
                          value={timeList[idx][0]}
                          onChange={e => {
                            if (e.target.value > 24 || reg.test(e.target.value)) return
                            let temp = timeList
                            temp[idx][0] = e.target.value
                            setInputValue(e.target.value)
                            setTimeList(temp)
                          }}
                          maxLength={2}
                        />
                        <p className="col-p">:</p>
                        <input
                          value={timeList[idx][1]}
                          onChange={e => {
                            if (e.target.value > 59 || reg.test(e.target.value)) return

                            let temp = timeList
                            temp[idx][1] = e.target.value

                            setInputValue(e.target.value)
                            setTimeList(temp)
                          }}
                          maxLength={2}
                        />
                      </div>
                      <div className="time-box">
                        <p className="text-p">완료시간</p>
                        <input
                          // value={inputValue}
                          value={timeList[idx][2]}
                          onChange={e => {
                            if (e.target.value > 24 || reg.test(e.target.value)) return
                            let temp = timeList
                            temp[idx][2] = e.target.value
                            setInputValue(e.target.value)
                            setTimeList(temp)
                          }}
                          maxLength={2}
                        />
                        <p className="col-p">:</p>
                        <input
                          value={timeList[idx][3]}
                          onChange={e => {
                            if (e.target.value > 59 || reg.test(e.target.value)) return

                            let temp = timeList
                            temp[idx][3] = e.target.value

                            setInputValue(e.target.value)
                            setTimeList(temp)
                          }}
                          maxLength={2}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="button-view">
          <button className="btn btn-primary font-white reverse" onClick={onClose}>
            취소
          </button>
          <button className="btn btn-primary font-white" onClick={onClickAdd}>
            확인
          </button>
        </div>
      </div>
    </LayerPopupLayout>
  )
}

export default MyContainer(UserContainer(TodayTodoPopup))

const Dummy = [
  {
    todoSeq: "1636595663760_mui3hvx9",
    subject: "수학",
    book: "수학의 정석",
    rangeType: "PAGE",
    rangeStart: 16,
    rangeEnd: 36,
    estimatedTimeRequired: "02:00:00",
    mediaContentUrl: null,
    mediaInfomation: null,
    comment: "가볍게 풀어봅시다.",
  },
  {
    todoSeq: "1636598898494_ewa675mf",
    subject: "영어",
    book: "EBS 수능특강 영어영역",
    rangeType: "PAGE",
    rangeStart: 30,
    rangeEnd: 60,
    estimatedTimeRequired: "03:00:00",
    mediaContentUrl: null,
    mediaInfomation: null,
    comment: "차분히 풀어봅시다.",
  },
  {
    todoSeq: "1637025978082_r7mgk8ru",
    subject: "수학",
    book: "쎈",
    rangeType: "PAGE",
    rangeStart: 1,
    rangeEnd: 20,
    estimatedTimeRequired: "02:00:00",
    mediaContentUrl: "https://youtu.be/yJIK1FMTtug",
    mediaInfomation: "샘플영상",
    comment: "즐겁게 풀어봅시다.",
  },
]
